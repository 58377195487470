.button {
    background-color: #A347FF;
    width: fit-content;
    border-radius: 8px;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;

}
.modalBox {
    background-color: #fff;
    width: fit-content;
    padding-top: 15px;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 26px;

}