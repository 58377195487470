.background {
    background-image: url('../../../public/background.jpg');
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.main-block {
    max-width: 38rem;
}
